import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { getIsFullNameSetInCommAppln } from '@/utility/memberFullNameSetUtil';

import { useAuthContext } from '@/contexts/AuthContext';

import { useCompleteProfile } from '@/hooks/member/useCompleteProfile';

const CompleteYourProfileModal = dynamic(
  () => import('./CompleteYourProfileModal'),
  { ssr: false }
);

export const getIsUserProfileComplete = (user) => {
  const isFullNameSetInCommAppln = getIsFullNameSetInCommAppln();
  return Boolean(
    user?.learner?.firstName ||
      user?.learner?.lastName ||
      isFullNameSetInCommAppln
  );
};

const CompleteYourProfileModalWrapper = ({
  onProfileUpdateComplete = null
}) => {
  const { user, isLoggedIn } = useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const {
    hasSeen,
    setHaveSeenProfileModal,
    loading: loadingIfHaveSeen
  } = useCompleteProfile();

  const isProfileComplete = Boolean(
    user?.learner?.firstName &&
      user?.learner?.description &&
      user?.learner?.socialMedia?.filter(
        (socialMedia) => socialMedia?.link
      )?.length > 0
  );

  const closeModal = async () => {
    setShowModal(false);
    if (onProfileUpdateComplete) {
      onProfileUpdateComplete();
    }
    await setHaveSeenProfileModal();
  };

  useEffect(() => {
    if (!loadingIfHaveSeen && !hasSeen && !isProfileComplete) {
      setTimeout(() => {
        setShowModal(true);
      }, [1000]);
    }
  }, [loadingIfHaveSeen, isProfileComplete, hasSeen, isLoggedIn]);

  return showModal ? (
    <CompleteYourProfileModal open={showModal} onClose={closeModal} />
  ) : null;
};

export default CompleteYourProfileModalWrapper;
