import sessionStorageService from './sessionStorageService';

export const SESSION_KEY_APPLN_FULL_NAME: string =
  'fullNameSetInCommunityApplication';

export const handleFullNameSetInCommAppln = (fullName: string): void => {
  sessionStorageService.setItem(SESSION_KEY_APPLN_FULL_NAME, fullName);
};

export const getFullNameSetInCommAppln = (): string => {
  return sessionStorageService.getItem(SESSION_KEY_APPLN_FULL_NAME) || '';
};

export const getIsFullNameSetInCommAppln = (): boolean => {
  return getFullNameSetInCommAppln() ? true : false;
};
