import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { t } from '@/utility/localization';
import { MEMBER_AFFILIATE_PRODUCTS_ROUTE } from '@/utility/routesHelper';

import { registerMemberAsAffiliate } from '@/services/affiliatesService';

import { useAuthContext } from '@/contexts/AuthContext';

import { showErrorToast } from '@/components/common/ToastContainer';
import { showSuccessToast } from '@/components/common/ToastContainer/ToastContainer';

import useQueryParams from '@/hooks/useQueryParams';

import { KEY_SHOW_JOIN_AS_AFFILIATE_MODAL } from '../constants';

type PropsType = {
  communityId: string;
  communityTitle: string;
  isAffiliateProduct: boolean;
  isCommunityAffiliate: boolean;
  isPendingApproval: boolean;
  isUserCommunitiesFetched: boolean;
  handleJoinAsAffiliateSuccessCb?: () => void;
};

const useLandingPageJoinAsAffiliateProgram = ({
  isAffiliateProduct,
  communityId,
  communityTitle,
  isUserCommunitiesFetched,
  isPendingApproval,
  isCommunityAffiliate,
  handleJoinAsAffiliateSuccessCb
}: PropsType) => {
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const { refreshUserData } = useAuthContext();
  const queryShowJoinAsAffiliateModal =
    !!currentRouterQuery?.[KEY_SHOW_JOIN_AS_AFFILIATE_MODAL];

  const [showJoinAsAffiliateModal, setShowJoinAsAffiliateModal] =
    useState(false);
  const [showJoinAsAffiliateCard, setShowJoinAsAffiliateCard] =
    useState(false);

  const [isSubmittingJoinAsAffiliate, setIsSubmittingJoinAsAffiliate] =
    useState(false);
  const [joinAsAffiliateError, setJoinAsAffiliateError] = useState(null);

  const onOpenJoinAsAffiliateModal = () => {
    setShowJoinAsAffiliateModal(true);
  };

  const onCloseJoinAsAffiliateModal = () => {
    setShowJoinAsAffiliateModal(false);
  };

  const onJoinAsAffiliate = async () => {
    setIsSubmittingJoinAsAffiliate(true);
    setJoinAsAffiliateError(null);

    const resp = await registerMemberAsAffiliate({
      communityId
    });

    if (resp.error) {
      showErrorToast(resp.error);

      setJoinAsAffiliateError(resp.error);
      setIsSubmittingJoinAsAffiliate(false);
      return resp;
    }

    handleJoinAsAffiliateSuccessCb?.();

    const successMsg = t('youre-now-an-affiliate-of-communitytitle', {
      communityTitle
    });
    showSuccessToast(successMsg);

    setIsSubmittingJoinAsAffiliate(false);
    onCloseJoinAsAffiliateModal();
    setShowJoinAsAffiliateCard(false);

    refreshUserData();

    // Go to user affiliate products page
    router.push(MEMBER_AFFILIATE_PRODUCTS_ROUTE);

    return resp;
  };

  const showBecomeAnAffiliateNavMenuItem = useMemo(() => {
    if (
      !isUserCommunitiesFetched ||
      isPendingApproval ||
      isCommunityAffiliate
    ) {
      return false;
    }

    return true;
  }, [isCommunityAffiliate, isPendingApproval, isUserCommunitiesFetched]);

  // Initialize show join affilicate card state
  useEffect(() => {
    if (!isAffiliateProduct) return;
    if (!isUserCommunitiesFetched) return;
    if (isPendingApproval) return;
    if (isCommunityAffiliate) return;

    setShowJoinAsAffiliateCard(true);
  }, [
    isAffiliateProduct,
    isUserCommunitiesFetched,
    isPendingApproval,
    isCommunityAffiliate
  ]);

  // Initialize show join affilicate modal state
  useEffect(() => {
    if (!isAffiliateProduct) return;
    if (!queryShowJoinAsAffiliateModal) return;
    if (!isUserCommunitiesFetched) return;
    if (isPendingApproval) return;
    if (isCommunityAffiliate) return;

    setShowJoinAsAffiliateModal(true);
  }, [
    isAffiliateProduct,
    queryShowJoinAsAffiliateModal,
    isUserCommunitiesFetched,
    isPendingApproval,
    isCommunityAffiliate
  ]);

  return {
    // navitem
    showBecomeAnAffiliateNavMenuItem,

    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate,
    joinAsAffiliateError
  };
};

export default useLandingPageJoinAsAffiliateProgram;
