import { communitySubscriptionStatus } from '@/data/communities';
import { useEffect, useState } from 'react';

import { roles } from '@/utility/constants';

import { getCommunitiesList } from '@/services/communitiesService';

import { useAuthContext } from '@/contexts/AuthContext';

import { COMMUNITY_EXPIRED } from '@/pages/portal/constants';
import { getExpiredStatus } from '@/pages/user/utils/communityUtils';

const getIsMemberInCommunity = (communityData) => {
  const communitySubscriptionDetails = communityData?.subscriptions[0];
  const subscriptionStatus = communitySubscriptionDetails?.status;

  const { current, cancelled } = communitySubscriptionStatus;

  // If status is current or status is cancelled and not expired, user is a member
  if (
    subscriptionStatus === current ||
    (subscriptionStatus === cancelled &&
      getExpiredStatus(communitySubscriptionDetails) !== COMMUNITY_EXPIRED)
  ) {
    return true;
  }

  return false;
};

export function getUserRoleInCommunity(communityCode, userCommunities) {
  let isPendingApproval = false;
  let isCommunityMember = false;
  let isCommunityAdmin = false;

  if (!Array.isArray(userCommunities))
    return {
      isPendingApproval,
      isCommunityMember,
      isCommunityAdmin,
      communityFound: false
    };

  const communityWithGivenCode = userCommunities?.find(
    (userCommunity) => userCommunity?.code === communityCode
  );

  if (!communityWithGivenCode) {
    return {
      isPendingApproval,
      isCommunityMember,
      isCommunityAdmin,
      communityFound: true
    };
  }

  // check if the users subscription is pending for approval.
  isPendingApproval = !!communityWithGivenCode?.subscriptions?.find(
    (communityInfo) =>
      communityInfo?.status === communitySubscriptionStatus?.pending
  );

  // Check if user is a communityMember
  isCommunityMember = getIsMemberInCommunity(communityWithGivenCode);

  const currUserRoles =
    communityWithGivenCode?.membersWithRoles?.[0]?.role || [];

  isCommunityAdmin = currUserRoles.includes(roles.manager);

  return {
    isPendingApproval,
    isCommunityMember,
    isCommunityAdmin,
    communityFound: true
  };
}

const useUserCommunities = (props) => {
  const activeCommunityId = props?.activeCommunityId;
  const [loading, setLoading] = useState(false);
  // Relying on this state to check if userCommunities are fetched then set the modal
  const [isUserCommunitiesFetched, setIsUserCommunitiesFetched] =
    useState(false);
  const [userCommunities, setUserCommunities] = useState([]);
  const [activeCommunity, setActiveCommunity] = useState(null);
  const [refetchUserCommunities, setRefetchUserCommunities] =
    useState(false);
  const { hasToken } = useAuthContext();

  const mutate = () => setRefetchUserCommunities((prev) => !prev);

  useEffect(() => {
    const fetchUserCommunities = async () => {
      setLoading(true);
      let payload = activeCommunityId ? { activeCommunityId } : {};

      const { data, error } = await getCommunitiesList(payload);

      if (error) {
        setLoading(false);
        return;
      }

      if (Array.isArray(data?.communities))
        setUserCommunities(data?.communities);

      if (data?.activeCommunity?.[0]) {
        setActiveCommunity(data.activeCommunity[0]);
      }
      setLoading(false);
      setIsUserCommunitiesFetched(true);
    };

    fetchUserCommunities();
  }, [hasToken, refetchUserCommunities, activeCommunityId]);

  return {
    loading,
    fetchingUserCommunities: loading,
    isUserCommunitiesFetched,
    userCommunities,
    activeCommunity,
    mutate
  };
};

export default useUserCommunities;
