import axios from 'axios';

import config from '@/utility/config';
import { downloadCSV } from '@/utility/helpers';
import { addQueryParamsToPath } from '@/utility/routesHelper';

import adminProtectedAxiosRequest from '@/services/helpers/adminProtectedAxiosRequest';

import { getAxiosErrorMsg } from './helpers';
import { TypeAdminProtectedAxiosReqResponse } from './helpers/adminProtectedAxiosRequestTypes';
import { getCsvApiToken } from './helpers/getCsvApiToken';

const { communityApiBasePath } = config;

type TypeEnableProdForAffilicatePayload = {
  // SUBSCRIPTION, EVENT, FOLDER, SESSION, CHALLENGE
  commissionPercentage: number;
  entityObjectId: string;
  entityType: string;
  messageForAffiliates: string;
};

export const getAffiliateProductsList = async ({
  communityId,
  pageNo,
  pageSize
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/products?pageNo=${pageNo}&pageSize=${pageSize}`;
  return await adminProtectedAxiosRequest.get(apiPath);
};

// CMP
export const enableProductForAffiliateAPI = (
  communityId,
  payload: TypeEnableProdForAffilicatePayload
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/products`;
  return adminProtectedAxiosRequest.post(path, payload);
};

export const disableProductForAffiliateAPI = (
  communityId: string,
  affiliateProductObjectId: string
) => {
  const payload = { isActive: false };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/products/${affiliateProductObjectId}`;
  return adminProtectedAxiosRequest.patch(path, payload);
};

export const updateProductForAffiliateAPI = ({
  communityId,
  affiliateProductObjectId,
  payload = {}
}: {
  affiliateProductObjectId: string;
  communityId: string;
  payload: {
    commissionPercentage?: number;
    isActive?: boolean;
    messageForAffiliates?: string;
  };
}) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/products/${affiliateProductObjectId}`;
  return adminProtectedAxiosRequest.patch(path, payload);
};

export const getAffiliatesList = ({
  communityId,
  pageNo,
  pageSize,
  search,
  status
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const queryParamsObj = { pageNo, pageSize, search, status };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryParamsObj);

  return adminProtectedAxiosRequest.get(pathWithQueryParams);
};

export const getAffiliateDetails = ({
  communityId,
  affiliateId,
  pageNo,
  pageSize
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const queryParamsObj = { pageNo, pageSize };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/${affiliateId}`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryParamsObj);

  return adminProtectedAxiosRequest.get(pathWithQueryParams);
};

export const updateAffiliateProductCommission = ({
  communityId,
  affiliateId,
  payload
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/${affiliateId}`;
  return adminProtectedAxiosRequest.patch(path, payload);
};

export const exportAffiliatesList = async ({
  communityId,
  search,
  status
}) => {
  const { data: token, error } = await getCsvApiToken(communityId);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  const queryParamsObj = { search, status, token };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/csv`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryParamsObj);

  downloadCSV(pathWithQueryParams);

  return { data: null, error: false };
};

export const addAffiliates = async ({ communityId, payload }) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates`;
  return adminProtectedAxiosRequest.post(path, payload);
};

export const removeAffiliate = ({
  communityId,
  affiliateId
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/${affiliateId}`;
  return adminProtectedAxiosRequest.delete(path);
};

// Member
export const getCommAffiliateProductsSumary = ({ communityId }) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/products/summary`;
  return axios.get(path);
};

export const registerMemberAsAffiliate = ({
  communityId
}: {
  communityId: string;
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const payload = { communityObjectId: communityId };
  const path = `${communityApiBasePath}/api/v1/members/affiliates`;
  return adminProtectedAxiosRequest.post(path, payload);
};

export const getAffiliateMembersProducts = ({
  pageNo,
  pageSize
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const queryParamsObj = { pageNo, pageSize };
  const path = `${communityApiBasePath}/api/v1/members/affiliates/products`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryParamsObj);

  return adminProtectedAxiosRequest.get(pathWithQueryParams);
};

export const getAffiliateMembersPayouts = ({
  pageNo,
  pageSize
}): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const queryParamsObj = { pageNo, pageSize };
  const path = `${communityApiBasePath}/api/v1/members/affiliates/payouts`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryParamsObj);

  return adminProtectedAxiosRequest.get(pathWithQueryParams);
};

export const getAffiliateMemberPayoutAccount =
  (): Promise<TypeAdminProtectedAxiosReqResponse> => {
    const path = `${communityApiBasePath}/api/v1/members/affiliates/payout-account`;
    return adminProtectedAxiosRequest.get(path);
  };

export const createAffiliatePayoutDetails = (payload) => {
  const path = `${communityApiBasePath}/api/v1/members/affiliates/payout-account`;
  return adminProtectedAxiosRequest.post(path, payload);
};

export const updateAffiliatePayoutDetails = (payload) => {
  const path = `${communityApiBasePath}/api/v1/members/affiliates/payout-account`;
  return adminProtectedAxiosRequest.patch(path, payload);
};

export const deleteAffiliatePayoutDetails =
  (): Promise<TypeAdminProtectedAxiosReqResponse> => {
    const path = `${communityApiBasePath}/api/v1/members/affiliates/payout-account`;
    return adminProtectedAxiosRequest.delete(path);
  };

export const getAffiliateInfo = ({
  affiliateCode,
  communityId,
  entityId,
  entityType
}) => {
  const queryObj = {
    affiliateCode,
    entityObjectId: entityId,
    entityType
  };

  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/affiliates/get-affiliate-info`;
  const pathWithQueryParams = addQueryParamsToPath(path, queryObj);
  return axios.get(pathWithQueryParams);
};
