import { useEffect, useState } from 'react';

import localStorageService from '@/utility/localStorageService';

import { useAuthContext } from '@/contexts/AuthContext';
import { useFirebaseContext } from '@/contexts/FirebaseContext';

const PROFILE_MODAL_SEEN_KEY = 'seenProfileCompleteModal';

export function useCompleteProfile() {
  const [hasSeen, setHasSeen] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const { user } = useAuthContext();
  const userId = user?._id;

  const { firestoreUtils } = useFirebaseContext();

  const fetchUserSeenProfileModal = async () => {
    if (!userId) return false;

    const docRef = firestoreUtils?.createDocRef(
      'seenProfileModal',
      userId
    );
    const data = await firestoreUtils?.fetchDoc(docRef);
    return Boolean(data?.hasSeen);
  };

  const setHaveSeenProfileModal = async () => {
    try {
      if (!userId) return;

      const docRef = firestoreUtils?.createDocRef(
        'seenProfileModal',
        userId
      );

      // Update firestore
      await firestoreUtils?.updateDoc(
        docRef,
        {
          hasSeen: true
        },
        {
          merge: true
        }
      );

      setHasSeen(true);
    } catch (error) {
      console.error(error);
    }
  };

  // TODO@TRENT Can be removed after at the end of the year
  // To make sure all have moved to firestore from local storage
  const migrateLocalStorageToFirestore = async () => {
    const localStorageData = JSON.parse(
      localStorageService.getItem(PROFILE_MODAL_SEEN_KEY) || '[]'
    );

    if (localStorageData.length > 0) {
      localStorageData.forEach((uid: string) => {
        const docRef = firestoreUtils?.createDocRef(
          'seenProfileModal',
          uid
        );
        firestoreUtils?.updateDoc(
          docRef,
          {
            hasSeen: true
          },
          { merge: true }
        );
      });

      localStorageService.removeItem(PROFILE_MODAL_SEEN_KEY);
    }
  };

  const fetchProfileModalData = async () => {
    try {
      // Migrate Localstorage to firestore first
      await migrateLocalStorageToFirestore();
      // Then check if user have seen the modal from firestore
      const hasSeenModal = await fetchUserSeenProfileModal();
      setHasSeen(hasSeenModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchProfileModalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    hasSeen,
    loading,
    setHaveSeenProfileModal
  };
}
