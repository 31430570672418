import {
  COMMUNITY_AUTO_RENEWAL_SOON,
  COMMUNITY_EXPIRED,
  COMMUNITY_GRACE_PERIOD,
  COMMUNITY_RENEWAL_AVAILABLE
} from '@/utility/member/constants';

function isDateLessThanXDaysInFuture(date, x) {
  var currentDate = new Date();
  var futureDate = new Date(date);

  // Calculate the difference in milliseconds between the current date and the future date
  var differenceInMilliseconds = futureDate - currentDate;

  // Calculate the number of days from milliseconds
  var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Check if the difference is less than 10 days
  return differenceInDays > 0 && differenceInDays < x;
}

export const getExpiredStatus = (communitySubscription) => {
  if (!communitySubscription) return null;

  const {
    status,
    cancelledAt,
    nextBillingDate,
    paymentProvider,
    recurringPurchase
  } = communitySubscription;

  const cancelledAtDate = new Date(cancelledAt);
  const currentDate = new Date();

  // Membership is expired
  if (status === 'Cancelled' && cancelledAtDate < currentDate)
    return COMMUNITY_EXPIRED;

  const autoRenewalProviders = ['stripe', 'ebanx', 'razorpay'];

  // Stripe will attempt auto renewal 7 days before the next billing date
  if (
    status === 'Current' &&
    isDateLessThanXDaysInFuture(nextBillingDate, 7) &&
    autoRenewalProviders.includes(paymentProvider)
  )
    return COMMUNITY_AUTO_RENEWAL_SOON;

  // If first recurring payment attempt failed and is in grace period (currently 7 days)
  if (
    status === 'Current' &&
    new Date(nextBillingDate) < new Date() &&
    autoRenewalProviders.includes(paymentProvider)
  )
    return COMMUNITY_GRACE_PERIOD;

  // Renewal window is open for Stripe-india users 10 days before the next billing date
  if (
    status === 'Current' &&
    isDateLessThanXDaysInFuture(nextBillingDate, 10) &&
    paymentProvider === 'stripe-india' &&
    recurringPurchase === false
  )
    return COMMUNITY_RENEWAL_AVAILABLE;

  return null;
};
